!<template>
    <div>
        <div class="container">
            <img class="img-1" src="@/assets/image/logo.svg"/>
            <div class="box1">
                <img class="img" src="@/assets/image/footer/地址.png"/>
                <div class="content">上海市杨浦区纪念路8号 财大科技园7号楼208室</div>
            </div>
            <div class="box2">
                <img class="img" src="@/assets/image/footer/电话@2x.png"/>
                <div class="content">021-65681880</div>
            </div>
            <div class="box3">
                <img class="img" src="@/assets/image/footer/邮箱@2x.png"/>
                <div class="content">marketing@bizfocus.cn</div>
            </div>
            <img class="img-2" src="@/assets/image/footer/公众号@2x.png"/>
            <div class="concern-us">关注我们</div>
            <div class="foot-list-title" style="margin-top:7.7rem">首页</div>
            <div class="foot-list-title" style="margin-top:5.3rem">行业方案</div>
            <div class="foot-list-content">
                <div class="title1">快消零售</div>
                <div class="title1" style="margin-left:5rem">医疗健康</div>
            </div>
            <div class="foot-list-title" style="margin-top:5.3rem">精选案例</div>
            <div class="foot-list-content">
                <div class="title1">快消零售</div>
                <div class="title1" style="margin-left:5rem">医疗健康</div>
            </div>
            <div class="foot-list-title" style="margin-top:5.3rem">技术服务</div>
            <div class="foot-list-content">
                <div class="title1">数据湖仓技术</div>
                <div class="title1" style="margin-left:5rem">SAP实施及运维</div>
                <div class="title1" style="margin-left:5rem">定制开发及运维</div>
            </div>
            <div class="foot-list-title" style="margin-top:5.3rem">新闻资讯</div>
            <div class="foot-list-title" style="margin-top:5.3rem">关于比孚</div>
            <div class="foot-list-title" style="margin-top:5.3rem">加入我们</div>
            <div class="foot-list-content" style="margin-top:5.5rem">
                <div class="title1">© 上海比孚信息科技有限公司版权所有 2023</div>
            </div>
            <div class="foot-list-content" style="margin-top:2rem">
                <div class="title1">沪ICP备13022622号-1</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusIndexMobileFoot',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.container{
    width: 100%;
    height: 176.3rem;
    background: #1E1E37;
    .box1{
        display: flex;
        margin-top: 5rem;
        margin-left: 4rem;
        .img{
            width: 3rem;
            height: 3rem;
        }
        .content{
            font-size: 2.8rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 6rem;
            margin: -1.5rem 0 0 1.5rem;
        }
    }
    .box2{
        display: flex;
        margin-top: 1rem;
        margin-left: 4rem;
        .img{
            width: 3rem;
            height: 3rem;
        }
        .content{
            font-size: 2.8rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 6rem;
            margin: -1.5rem 0 0 1.5rem;
        }
    }
    .box3{
        display: flex;
        margin-top: 1rem;
        margin-left: 4rem;
        .img{
            width: 3rem;
            height: 3rem;
        }
        .content{
            font-size: 2.8rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 6rem;
            margin: -1.5rem 0 0 1.5rem;
        }
    }
    
}
.img-1{
   width: 25.4rem;
   height: 8.1rem;
   margin: 7rem 0 0 4rem; 
}
.img-2{
    width: 25.3rem;
    height: 25.3rem;
    margin-top: 6.3rem ;
    margin-left:calc(50% - 12.4rem);
}
.concern-us{
    font-size: 2.8rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #FFFFFF;
    line-height: 3.6rem;
    text-align: center;
    margin-top: 1rem;
}
.foot-list-title{
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #FFFFFF;
    line-height: 4.2rem;
    margin-left: 4rem;
}
.foot-list-content{
    margin: 2.3rem 0 0 4rem;
    display: flex;
    .title1{
        font-size: 2.7rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #858A9D;
        line-height: 3.2rem;
    }
}
</style>
