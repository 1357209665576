<template>
    <div class="container">
        <van-nav-bar>
            <template #left>
                <img v-if="(isMain === 1)" class="mobile-head-logo" src="../../assets/image/logo-黑.svg" @click="jumpMobileRoute('/mobile-index')"/>
                <img v-if="(isMain === 2)" class="menu-icon-style-back" src="../../assets/image/mobile/返回.png" @click="jumpMobileRoute('/mobile-news-index')"/>
                <img v-if="(isMain === 3)" class="menu-icon-style-back" src="../../assets/image/mobile/返回.png" @click="jumpMobileRoute('/mobile-programme-sale')"/>
                <img v-if="(isMain === 4)" class="menu-icon-style-back" src="../../assets/image/mobile/返回.png" @click="jumpMobileRoute('/mobile-programme-medical')"/>
            </template>
            <template #right>
                <img v-if="!isShowMenu" class="menu-icon-style" @click="showMenu" src="../../assets/image/mobile/更多.png"/>
                <img v-if="isShowMenu" class="menu-icon-style" @click="showMenu" src="../../assets/image/mobile/关闭.png"/>
            </template>
        </van-nav-bar>
        <el-collapse-transition>
           <el-menu
                v-if="isShowMenu"
                default-active="2"
                class="el-menu-vertical-demo"
                active-text-color="#11A84F"
                background-color="#F9F9F9"
            >
                <el-menu-item index="1" @click.native="jumpMobileRoute('/mobile-index')">
                    <span slot="title">主页</span>
                </el-menu-item>
                <el-submenu index="2">
                    <template slot="title">
                    <span>行业方案</span>
                    </template>
                    <el-submenu index="2-1">
                        <span slot="title" @click="jumpMobileRoute('/mobile-programme-sale')">快消零售</span>
                        <el-menu-item index="2-1-1"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-purchase')">采购协同</div></el-menu-item>
                        <el-menu-item index="2-1-2"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-warehousing')">智能仓储</div></el-menu-item>
                        <el-menu-item index="2-1-3"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-DPM')">DPM数字化工单</div></el-menu-item>
                        <el-menu-item index="2-1-4"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-line')">数字化生产</div></el-menu-item>
                        <el-menu-item index="2-1-5"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-APS')">APS高级排程</div></el-menu-item>
                        <el-menu-item index="2-1-6"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-observation')">在线安全观察</div></el-menu-item>
                    </el-submenu>
                    <el-submenu index="2-2">
                        <span slot="title"  @click="jumpMobileRoute('/mobile-programme-medical')">医疗健康</span>
                        <el-menu-item index="2-2-1"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-efficiency')">销售效能管理</div></el-menu-item>
                        <el-menu-item index="2-2-2"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-operate')">销售运营管理</div></el-menu-item>
                        <el-menu-item index="2-2-3"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-science')">学术会议管理</div></el-menu-item>
                        <el-menu-item index="2-2-4"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-market')">市场准入与商务运营</div></el-menu-item>
                    </el-submenu>
                    <!-- <el-menu-item index="2-1">
                        <div class="head-title" @click="jumpMobileRoute('/mobile-programme-sale')">快消零售</div>
                    </el-menu-item> -->
                    <!-- <el-menu-item index="2-2"><div class="head-title" @click="jumpMobileRoute('/mobile-programme-medical')">医疗健康</div></el-menu-item> -->
                </el-submenu>
                <el-submenu index="8">
                    <template slot="title">
                    <span>产品</span>
                    </template>
                    <el-menu-item index="8-1">
                      <div class="product-card" @click="handleJumpTo()">
                        <img class="product-card-image" src="@/assets/image/index/vticket.svg" alt="">
                        <span class="head-title">不一样的服务管理平台</span>
                      </div>
                    </el-menu-item>
                    <el-menu-item index="8-2">
                      <div class="product-card" @click="handleJumpTo()">
                        <img class="product-card-image" src="@/assets/image/index/vproject.svg" alt="">
                        <span class="head-title">高效的项目管理平台</span>
                      </div>
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">
                    <span>精选案例</span>
                    </template>
                    <el-submenu index="3-1">
                        <span slot="title">精选案例</span>
                        <el-menu-item index="3-1-1"><div class="head-title" @click="jumpMobileRoute('/mobile-case-warehousing')">智能仓储</div></el-menu-item>
                        <el-menu-item index="3-1-2"><div class="head-title" @click="jumpMobileRoute('/mobile-case-iDAS')">iDAS整合数据分析系统</div></el-menu-item>
                        <el-menu-item index="3-1-3"><div class="head-title" @click="jumpMobileRoute('/mobile-case-line')">数字化产线</div></el-menu-item>
                        <el-menu-item index="3-1-4"><div class="head-title" @click="jumpMobileRoute('/mobile-case-business')">商情诊断管理分析</div></el-menu-item>
                        <el-menu-item index="3-1-5"><div class="head-title" @click="jumpMobileRoute('/mobile-case-5GF')">5GF整合数据分析系统</div></el-menu-item>
                        <el-menu-item index="3-1-6"><div class="head-title" @click="jumpMobileRoute('/mobile-case-dashboard')">Dashboard数据实施</div></el-menu-item>
                        <el-menu-item index="3-1-7"><div class="head-title" @click="jumpMobileRoute('/mobile-case-permeability')">市场渗透率分析</div></el-menu-item>
                        <el-menu-item index="3-1-8"><div class="head-title" @click="jumpMobileRoute('/mobile-case-lake')">数据湖迁移</div></el-menu-item>
                        <el-menu-item index="3-1-9"><div class="head-title" @click="jumpMobileRoute('/mobile-case-integrated')">数据湖仓一体化平台</div></el-menu-item>
                        <el-menu-item index="3-1-10"><div class="head-title" @click="jumpMobileRoute('/mobile-case-indicators')">区域指标系统</div></el-menu-item>
                        <el-menu-item index="3-1-11"><div class="head-title" @click="jumpMobileRoute('/mobile-case-masterdata')">主数据管理系统</div></el-menu-item>
                    </el-submenu>
                    <el-submenu index="3-2">
                        <span slot="title">产品案例</span>
                        <el-menu-item index="3-2-1"><div class="head-title" @click="jumpMobileRoute('/mobile-produt-case-pharmaceutical')">某大型医药企业</div></el-menu-item>
                        <el-menu-item index="3-2-2"><div class="head-title" @click="jumpMobileRoute('/mobile-produt-case-metallurgy')">某大型国有冶金集团</div></el-menu-item>
                        <el-menu-item index="3-2-3"><div class="head-title" @click="jumpMobileRoute('/mobile-produt-case-technology')">某知名传媒科技公司</div></el-menu-item>
                        <el-menu-item index="3-2-4"><div class="head-title" @click="jumpMobileRoute('/mobile-produt-case-catering')">某全国连锁餐饮企业</div></el-menu-item>
                        <el-menu-item index="3-2-4"><div class="head-title" @click="jumpMobileRoute('/mobile-produt-case-retail')">某大型零售连锁企业</div></el-menu-item>
                    </el-submenu>
                </el-submenu>
                <el-submenu index="4">
                    <template slot="title">
                    <span>技术服务</span>
                    </template>
                    <el-menu-item index="4-1"><div class="head-title" @click="jumpMobileRoute('/mobile-service-lake')">数据湖仓技术</div></el-menu-item>
                    <el-menu-item index="4-2"><div class="head-title" @click="gotoOutLine">SAP实施及运维</div></el-menu-item>
                    <el-menu-item index="4-3"><div class="head-title" @click="jumpMobileRoute('/mobile-service-customized')">定制开发及运维</div></el-menu-item>
                </el-submenu>
                <el-menu-item index="5" @click.native="jumpMobileRoute('/mobile-news-index')">
                    <span slot="title" >新闻资讯</span>
                </el-menu-item>
                <el-menu-item index="6" @click.native="jumpMobileRoute('/mobile-aboutus-index')">
                    <span slot="title" >关于比孚</span>
                </el-menu-item>
                <el-menu-item index="7" @click.native="jumpMobileRoute('/mobile-joinus-index')">
                    <span slot="title" >加入我们</span>
                </el-menu-item>
            </el-menu>
        </el-collapse-transition>

    </div>
</template>

<script>
export default {
    name: 'BizfocusIndexHeadTitle',
    props: {
        isMain:{
            type: Number,
            default: 1,
        }
    },
    data() {
        return {
            activeNames: [],
            isShowMenu: false,
        };
    },

    mounted() {

    },

    methods: {
        showMenu(){
            this.isShowMenu = !this.isShowMenu;
        },
        gotoOutLine(){
            window.location.href = 'http://www.asialink.sh.cn/pc/home.html#'
        },
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        },
        handleJumpTo() {
            // window.location.href = 'https://visualticket.cn/'
            window.open('https://visualticket.cn/', '_blank');
        }
    },
};
</script>

<style lang="less" scoped>
.container{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 101;
}
.van-nav-bar {
    height: 12rem;
}
.mobile-head-logo{
    height: 5rem;
    width: 15.9rem;
    // border: 1px solid;
    margin: 6rem 0 0 2rem;
}
.van-collapse-item{
    color: #F00;
}
.more-icon{
    margin-top: 6rem;
    margin-right: 3rem;
}
.head-title{
    font-family: 'CN_Normal';
}
.el-menu-vertical-demo{
    position: absolute;
    width: 100%;
    max-height: 100rem;
    overflow-y: auto;
    z-index: 100;
}
.menu-icon-style{
    width: 3.8rem;
    height: 3.8rem;
    margin-top: 5rem;
    margin-right: 3rem;

}
.menu-icon-style-back{
    width: 3.8rem;
    height: 3.8rem;
    margin-top: 6rem;
    margin-left: 3rem;

}
/deep/ .el-menu-vertical-demo>.el-menu-item.is-active {
    // border-bottom: 0.2rem solid #11A84F;
    background-color: rgb(0 0 0 / 0%);
}
/deep/ .el-menu-vertical-demo .el-menu-item{
        // margin-left: 5rem;
        color: #000000;
        font-family: 'CN_Medium';
        font-size: 3.2rem;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu-vertical-demo .el-menu-item:hover{
        color: #11A84F;
    }
    /deep/ .el-menu-vertical-demo .el-menu-item:hover{
        // margin: 0 2rem;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu-vertical-demo{
        border-bottom: solid 0rem #e6e6e6;
    }

    /deep/ .el-menu-vertical-demo>.el-submenu .el-submenu__title{
        color: #000000;
        font-family: 'CN_Medium';
        font-size: 3.2rem;
    }
    /deep/ .el-menu-vertical-demo>.el-submenu .el-submenu__title:hover{
        color: #11A84F !important;
    }
    // /deep/ .el-menu-vertical-demo>.el-submenu .el-submenu__title:hover{
    //     color: #000000;
    //     background-color: rgb(0 0 0 / 0%);
    // }
    // /deep/ .el-menu-vertical-demo>.el-submenu.is-active .el-submenu__title {
    //     // border-bottom: 0.2rem solid #11A84F;
    //     background-color: rgb(0 0 0 / 0%);
    // }
    // /deep/ .el-menu-vertical-demo>.el-menu-item.is-active{
    //     color: #fff;
    // }
    // /deep/ .el-menu-vertical-demo>.el-menu-item.is-active {
    //     // border-bottom: 0.2rem solid #11A84F;
    //     background-color: rgb(0 0 0 / 0%);
    // }
    // /deep/ .el-item .el-submenu .is-opened{
    //     // background-color: rgb(0 0 0 / 0%);
    //     color: #fff;
    // }
.product-card {
  display: flex;
}
.product-card-image {
  width: 20rem;
  margin-right: 4rem;
}
</style>
